export default {
  methods:{

    admin_site_path(potential_shopify_site_document_id){
      return `/admin/sites/${potential_shopify_site_document_id}/edit`;
    },

    store_site_path(region, shopify_site_document_id){
      return `/${region}/sites/${shopify_site_document_id}`;
    },

    store_site_product_path(region, shopify_site_document_id, shopify_product_document_id){
      return `${this.store_site_path(region,shopify_site_document_id)}/products/${shopify_product_document_id}`;
    },

    product_path(region, category_name, shopify_product_document_id){
      return `${this.category_path(region, category_name)}/products/${shopify_product_document_id}`;
    },

    store_latest_path(region){
      return `/${region}`;
    },

    store_latest_product_path(region, shopify_product_document_id){
      return `/${region}/latest/products/${shopify_product_document_id}`;
    }
  }
}
