export default function(editor, opts) {
    const blockManager = editor.BlockManager;
    const components = editor.DomComponents;
    const defaultType = components.getType('default');
    const defaultModel = defaultType.model;
    const defaultView = defaultType.view;
    const textType = components.getType('text');
    
    const { category, blocks, stylePrefix, flexGrid, rowHeight, addBasicStyle } = opts


    const privateCls = [".editor__container", ".editor__block", ".accordion", ".accordion__radio", ".accordion__Label", ".accordion__content", ".page",".page__container",".page__heading",".page__content",".breadcrumbs",".breadcrumbs__link",".breadcrumb__divider", ".breadcrumbs__text"];
    //editor.on("selector:add", selector => privateCls.indexOf(selector.getFullName()) >= 0 && selector.set("private", 1))


    const commonBlockProps = {category, select: true }

    const margin_defaults = "margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px;padding-top:0px;padding-bottom:0px;padding-left:0px;padding-right:0px;"


    blockManager.add("section", {
        ...commonBlockProps,
        label: "Section",
        media: `<svg viewBox="0 0 24 24"><path fill="currentColor" d="M2 20h20V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Z"/></svg>`,
        content: `<div class="editor__container"
                        style="position:relative; display:flex; flex:1 1 auto; max-width:100%; justify-content:flex-start; align-items:center;"
                        data-gjs-stylable-require="['display', 'max-width', 'justify-content', 'align-items', 'flex', 'flex-wrap', 'margin', 'padding', 'backgroundcolour', 'border', 'border-radius' ]"
                        data-gjs-name="Section"
                        data-gjs-droppable="true"></div>`
    })


    blockManager.add("page-container", {
        ...commonBlockProps,
        label: "Page Container",
        media: `<svg viewBox="0 0 22.3 18.5"><path fill="currentColor" d="M1.1,17.2h20v-16h-20V17.2z M0.1,17.2v-16c0-0.6,0.4-1,1-1h20c0.6,0,1,0.4,1,1v16c0,0.6-0.4,1-1,1h-20\tC0.6,18.2,0.1,17.8,0.1,17.2z"/><rect fill="currentColor" x="5" y="0.6" width="1" height="17.2"/><rect fill="currentColor" x="16.7" y="0.6" width="1" height="17.2"/><rect fill="currentColor" x="10.9" y="-2.2" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 14.8638 -7.9226)" width="1" height="11.3"/></svg>
`,
        content: `
<div class="page" data-gjs-name="Page Container" data-gjs-selectable="false">
    <div class="breadcrumbs" data-gjs-name="Breadcrumbs" data-gjs-selectable="false" >
      <a class="breadcrumbs__link" href="/"  data-gjs-name="Breadcrumb Link" data-gjs-selectable="false" >Home</a>
      <div class="breadcrumb__divider" data-gjs-name="Breadcrumb Divider"></div>
      <div class="breadcrumbs__text" data-gjs-name="Breadcrumb Text">Some Heading</div>
    </div>
    <div class="page__container" data-gjs-name="Page Body" data-gjs-selectable="false">
      <h1 class="page__heading"  data-gjs-name="Page Heading">Some Heading</h1>
      <div class="page__content" data-gjs-droppable="true" data-gjs-name="Page Content" data-gjs-selectable="false">
  
      </div>
    </div>
  </div>
`
    });


    blockManager.add("overlay", {
        ...commonBlockProps,
        label: "Overlay",
        media: `<svg viewBox="0 0 27.3 21.2"><path fill="currentColor" d="M5.5,17.3H2v-16h20v2.3l1,0.2V1.3c0-0.6-0.4-1-1-1H2c-0.6,0-1,0.4-1,1v16c0,0.6,0.4,1,1,1h3.5L5.5,17.3z"/><path fill="currentColor" d="M6,20h20V4H6V20z M5,20V4c0-0.6,0.4-1,1-1h20c0.6,0,1,0.4,1,1v16c0,0.6-0.4,1-1,1H6C5.4,21,5,20.6,5,20z"/></svg>`,
        content: `<div class="editor__overlay"
                        style="display:block; max-width:100%; max-height:100%; position:absolute;" 
                        data-gjs-stylable-require="['display', 'top', 'bottom','left','right', 'z-index', 'padding', 'backgroundcolour', 'border', 'border-radius']"
                        data-gjs-name="Overlay"
                        data-gjs-droppable="true"></div>`
    })

    blockManager.add("image", {
        ...commonBlockProps,
        activate: true,
        label: opts.labelImage,
        media: `<svg viewBox="0 0 24 24"><path fill="currentColor" d="M21,3H3C2,3 1,4 1,5V19A2,2 0 0,0 3,21H21C22,21 23,20 23,19V5C23,4 22,3 21,3M5,17L8.5,12.5L11,15.5L14.5,11L19,17H5Z" /></svg>`,
        content: {
            type: "image",
            style: { display:'block', 'max-width':'100%;', 'min-width':0 },
            'stylable-require': "['max-width', 'margin']",
        }
    })

    blockManager.add("video", {
        ...commonBlockProps,
        label: opts.labelVideo,
        media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
      </svg>`,
        content: {
            type: "video",
            src: "img/video2.weblockManager",
            style: {
                height: "350px",
                width: "615px"
            }
        }
    })


    blockManager.add("text", {
        ...commonBlockProps,
        activate: true,
        label: "Text",
        media: `<svg viewBox="0 0 24 24"><path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" /></svg>`,
        content: {
            type: "text",
            name: "Text",
            class: "editor__text",
            content: "Insert your text here",
            style: { 'font-family': "'Poppins', sans-serif", 'font-size': '14px;', 'letter-spacing': '0.05em', 'line-height': 1.4, 'color':'#000000',   },
            'stylable-require': "['margin', 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-align'"
        }
    });


    blockManager.add("button", {
        ...commonBlockProps,
        activate: true,
        label: "Button",
        media: `<svg viewBox="0 0 32 20"><path fill="currentColor" d="M22.6,15H8.8c-2.5,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6h13.8c2.5,0,4.6,2.1,4.6,4.6S25.1,15,22.6,15z M8.8,6.8
\t\tc-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6h13.8c2,0,3.6-1.6,3.6-3.6c0-2-1.6-3.6-3.6-3.6H8.8z"/></svg>`,
        content: {
            type: "link",
            name: "Button",
            class: "editor__button",
            content: "Button Text",
            style: { 'font-family': "'Poppins', sans-serif", 'padding': '8px 24px 8px 24px', 'font-weight':500, 'font-size': '11px;', 'text-transform': 'uppercase', 'white-space': 'nowrap', 'letter-spacing': '0.1em', 'line-height': 1, 'color':'#ffffff', 'background-color':'#000000', 'border-radius':'20px' },
            'stylable-require': "['margin', 'padding' 'font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'background-color', 'border-radius', 'line-height', 'text-align'"
        }
    });

    editor.Components.addType('accordion', {
        extend: 'text',
        isComponent: el => { return (el.classList && el.classList.contains("accordion")) },
        model: {
            defaults: {
              //  traits: [{ name: 'for' }],
            },
        },
        view: {
            init() {
                const new_id = "accordion_" + ( new Date().getTime());
                const input = this.model.attributes.components.models.find( u => u.attributes.tagName === "input" );
                const label = this.model.attributes.components.models.find( u => u.attributes.tagName === "label" );
                input.set({ attributes: { id: new_id, type: "checkbox" }});
                label.set({ attributes: { for: new_id }});
            }
        }
    });



    blockManager.add("accordion", {
        ...commonBlockProps,
        type: "accordion",
        label: "Accordion",
        media: `<svg viewBox="0 0 27.3 21.2"><rect fill="currentColor" x="0" y="2" width="24" height="0.3" /><rect fill="currentColor" x="0" y="21" width="24" height="0.3" /><path fill="currentColor" d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" /></svg>`,
        content: `<div class="accordion" data-gjs-name="Accordion" v-accordion>
            <input class="accordion__radio" type="checkbox" checked="checked" data-gjs-name="Accordion Trigger" data-gjs-selectable="false" />
            <label class="accordion__Label" data-gjs-name="Accordion Content"  data-gjs-selectable="false" data-gjs-draggable="false" data-gjs-removable="false" >Accordion Heading</label>
            <div class="accordion__content" data-accordion-content  data-gjs-name="Accordion Content" data-gjs-selectable="false" data-gjs-droppable="true" data-gjs-draggable="false" data-gjs-removable="false" >Accordion Content</div>
         </div>`
    });






    /*

        const clsRow = `${stylePrefix}row`
    const clsCell = `${stylePrefix}cell`
    const styleRow =
        `
    .${clsRow} {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      flex-wrap: nowrap;
      padding: 10px;
    }
    @media (max-width: 768px) {
      .${clsRow} {
        flex-wrap: wrap;
      }
    }`
    const styleClm = `
    .${clsCell} {
      min-height: ${rowHeight}px;
      flex-grow: 1;
      flex-basis: 100%;
    }`;


    const step = 0.2
    const minDim = 1
    const currentUnit = 1
    const resizerBtm = { tl: 0, tc: 0, tr: 0, cl: 0, cr: 0, bl: 0, br: 0, minDim }
    const resizerRight = { ...resizerBtm, cr: 1, bc: 0, currentUnit, minDim, step }
    resizerRight.keyWidth = "flex-basis"


    const rowAttr = {
        class: clsRow,
        "data-gjs-droppable": `.${clsCell}`,
        "data-gjs-name": "Row"
    } // "data-gjs-resizable": resizerBtm,

    const colAttr = {
        class: clsCell,
        "data-gjs-draggable": `.${clsRow}`,
        "data-gjs-name": "Cell",
        "data-gjs-unstylable": ["width"],
        "data-gjs-stylable-require": ["flex-basis"]
    } //"data-gjs-resizable": resizerRight,



    const attrsToString = attrs => {
        const result = []

        for (let key in attrs) {
            let value = attrs[key]
            const toParse = value instanceof Array || value instanceof Object
            value = toParse ? JSON.stringify(value) : value
            result.push(`${key}=${toParse ? `'${value}'` : `'${value}'`}`)
        }

        return result.length ? ` ${result.join(" ")}` : ""
    }

    const attrsRow = attrsToString(rowAttr)
    const attrsCell = attrsToString(colAttr)

    blockManager.add("column1", {
        ...commonBlockProps,
        label: opts.labelColumn1,
        media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M2 20h20V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Z"/>
      </svg>`,
        content: `<div ${attrsRow}>
        <div ${attrsCell}></div>
      </div>
      ${
            addBasicStyle
                ? `<style>
          ${styleRow}
          ${styleClm}
        </style>`
                : ""
        }`
    })

    blockManager.add("link", {
        ...commonBlockProps,
        label: opts.labelLink,
        media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z" />
      </svg>`,
        content: {
            type: "link",
            content: "Link",
            style: { color: "#d983a6" }
        }
    })

    blockManager.add("map", {
        ...commonBlockProps,
        label: opts.labelMap,
        media: `<svg viewBox="0 0 24 24">
        <path fill="currentColor" d="M20.5,3L20.34,3.03L15,5.1L9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21L3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19.03 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3M10,5.47L14,6.87V18.53L10,17.13V5.47M5,6.46L8,5.45V17.15L5,18.31V6.46M19,17.54L16,18.55V6.86L19,5.7V17.54Z" />
      </svg>`,
        content: {
            type: "map",
            style: { height: "350px" }
        }
    })*/
}
