
import { SupabaseClient } from './SuperbaseClient'


export default class SupaBaseModel {
    static data_name; // Firestore table name must be defined in subclass

    // Queries

    static async get(id){ console.log("get", id);
       let { data, error }  = await this.client.select().match({ id: id});

       if(error) throw error;

       if(data.length > 0)
           return this.instanceFromData(data[0]);

       return null;
    }

    static async all(limit = 1000) {
        const { data, error } = await this.client.select().limit(limit);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }

    static async allOrdered(column, ascending = true, limit = 1000) {
        const { data, error } = await this.client.select().order(column, { ascending: ascending }).limit(limit);

        if(error)
            throw error;

        return this.instanceItemsFromData(data);
    }


    // Static Utils

    static get supabase(){
        return SupabaseClient;
    }

    static get client(){
        return SupabaseClient.from(this.data_name);
    }

    static instanceItemsFromData(data_array){
        return data_array.map(data => this.instanceFromData(data));
    }

    static instanceFromData(data){
        let instance = new this();
        Object.assign(instance, data);
        return instance;
    }

    async save(){
        const { error } = await this.constructor.client.upsert(this.toData());
        if(error) throw error;
    }

    async create(){
        const { data, error } = await this.constructor.client.insert(this.toData()).select();
        if(error) throw error;
        if (data.length > 0){
            Object.assign(this, data[0]);
        }
    }

    toData() {
        return Object.assign({}, this);
    }

    formatDate(date_string){
        return (new Date(date_string)).toLocaleString('en-GB', {
            year: 'numeric', month: 'short', day: 'numeric',
            timeZone: 'Australia/Sydney' })
    }

    formatDateWithTime(date_string){
        return (new Date(date_string)).toLocaleString('en-GB', {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: '2-digit', minute:'2-digit', hour12: true,
            timeZone: 'Australia/Sydney' })
    }

    /*
    // let document_id; name of document assigned to document_id


    // Queries

    static async get(id) {
        const doc = await getDoc(this.documentRef(id));
        return this.instanceFromDocument(doc);
    }

    static async getBy(field, value){
        let results = await this.where(field, '==', value, 1);
        return results.length > 0 ? results[0] : null;
    }


    static async exists(id) {
        const doc = await getDoc(this.documentRef(id));
        return doc.exists();
    }

    static async all(page_size = 1000) {
        const q = query(this.collectionRef, limit(page_size));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    static async page(order_by, start_at = null, page_size = 100) {
        const q = query(this.collectionRef, orderBy(order_by), startAt(start_at), limit(page_size));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    // Usage: where('age', '>=', 18)
    // Valid Operators: '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
    static async where(fieldPath, opStr, value, results_limit = 1000) {
        const q = query(this.collectionRef, where(fieldPath, opStr, value), limit(results_limit));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    static async whereOrderedBy(fieldPath, opStr, value, order_by, results_limit = 1000) {
        const q = query(this.collectionRef, where(fieldPath, opStr, value), orderBy(order_by), limit(results_limit));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    static async getQuery(q) {
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    // Usage: whereAnd(['age', '>=', 18], ['gender', '==', 'female')
    // Valid Operators: '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
    static async whereAnd(...paramArray) {
        const whereParams = paramArray.map( params => where(params[0], params[1], params[2]))
        const q = query(this.collectionRef, ...whereParams);
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }


    static async startWithText(fieldPath, value, results_limit = 1000) {
        const q = query(this.collectionRef,
            orderBy(fieldPath),
            startAt(value),
            endAt(value + "~"),
            limit(results_limit));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    // Sub Collections

    static async getSubCollection(parent, page_size = 1000) {
        const q = query(collection(parent.doc_ref, this.data_name), limit(page_size));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => {
            let instance = this.instanceFromDocument(doc);
            instance.parent = parent;
            return instance;
        });
    }

    static async getSubCollectionDocument(parent, id) {

        //console.log("sub", parent.constructor.data_name, parent.document_id, this.data_name, id)

        const docRef = await getDoc(doc(parent.doc_ref, this.data_name, id));

        //console.log("exists", docRef.exists());

        let instance = this.instanceFromDocument(docRef);

        //console.log("instance", instance);

        if(instance) instance.parent = parent;

        return instance;
    }

    // Realtime Updates

    // Usage: onCollectionUpdate(documents => { for(let doc of documents) console.log("doc", doc) }, ['age', '>=', 18], ['gender', '==', 'female'])
    // Valid Operators: '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
    static onCollectionUpdate(listener, ...paramArray){
        const whereParams = paramArray.map( params => where(params[0], params[1], params[2]))
        const q = query(this.collectionRef, ...whereParams);
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            listener(querySnapshot.docs.map(doc => this.instanceFromDocument(doc)));
        });
        return unsubscribe;
    }

    // Usage: onDocumentUpdate(documents => { for(let doc of documents) console.log("doc", doc) }, ['age', '>=', 18], ['gender', '==', 'female'])
    // Valid Operators: '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in' | 'array-contains-any' | 'not-in';
    static onDocumentUpdate(id, listener){
        const unsubscribe = onSnapshot(this.documentRef(id), doc => {
            listener(this.instanceFromDocument(doc));
        });
        return unsubscribe;
    }

    static onChildDocumentUpdate(parent, id, listener){
        const unsubscribe = onSnapshot(this.childDocumentRef(parent, id), doc => {
            let instance = this.instanceFromDocument(doc);
            instance.parent = parent;
            listener(instance);
        });
        return unsubscribe;
    }



    static timestamp(){
        return Timestamp.now();
    }

    static documentRef(id){
        return doc(db, this.data_name, id);
    }

    static childDocumentRef(parent, id){
        return doc(parent.doc_ref, this.data_name, id);
    }

    static get collectionRef(){
        return collection(db, this.data_name);
    }

    static async getInstances(q){
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => this.instanceFromDocument(doc));
    }

    static instanceFromDocument(doc){
        if(!doc.exists()) return null;
        let instance = new this();
        Object.assign(instance, doc.data());
        instance.document_id = doc.id;
        return instance;
    }



    // Instance Methods

    async save(overwrite = false){
        if(this.document_id){
            await setDoc(this.doc_ref, this.toFirebaseDocument(), { merge: !overwrite });
        } else {
            let doc = await addDoc(collection(this.parent_ref, this.constructor.data_name), this.toFirebaseDocument());
            this.document_id = doc.id;
        }
        return true;
    }

    / *async save(overwrite = false){
        if(this.document_id){
            return await setDoc(this.docRef, this.toFirebaseDocument(), { merge: !overwrite });
        } else {
            let documentRef = await addDoc(this.constructor.collectionRef, this.toFirebaseDocument());
            this.document_id = documentRef.id;
            return documentRef;
        }
    }* /

    get parent_ref(){
        return this.parent !== undefined ? this.parent.doc_ref : db;
    }

    get doc_ref(){
        return doc( this.parent_ref, this.constructor.data_name, this.document_id)
    }

    / *
    get docRef(){
        return doc(db, this.constructor.data_name, this.document_id)
    }* /

    toFirebaseDocument(){
        const { document_id, parent, ...without_document_id } = Object.assign({}, this);
        return without_document_id;
    }
    */
}
