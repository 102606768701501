import SupaBaseModel from "./SupaBaseModel";

export default class Cboe extends SupaBaseModel {
    static data_name = 'cboes';

    static async scan(search_params, order_column, order_ascending){

        console.log("search_params", search_params);

        const { symbols, type, strike, days_before_expiration, bid_over_ask, pop, rating, open_interest, include_null_ratings } = search_params;

       //  console.log("type", type);
       // console.log("strike", strike);
       // console.log("days_before_expiration",  days_before_expiration);
       // console.log("pop", pop);
       // console.log("bid_over_ask", bid_over_ask);
       // console.log("rating", rating);

        const spread = 1.0 - (bid_over_ask.max_value / 100.0);
        console.log("spread", spread);

        let is_specific_search = type.includes(":specific");

        let type_match = { type: type.replaceAll(":specific", "") };

        if(type == "*") type_match = {};

        let analyst_rating_condition = `analyst_rating.gte.${rating.min_value}`;
        if(include_null_ratings) analyst_rating_condition += ",analyst_rating.is.null";

        console.log("open_interest",  open_interest)

        let symbols_to_search = symbols.toUpperCase().replaceAll(" ", "");

        console.log("type_match", type_match, " symbols_to_search:", symbols_to_search);

        const { data, error } = (is_specific_search && symbols_to_search.length > 1) ?

            await this.supabase.from('cboe_fundatmentals').select()
            //.eq('is_in_the_money', false)
            .gte('days_before_expiration', days_before_expiration.min_value).lte('days_before_expiration', days_before_expiration.max_value)
            .gte('bid_over_ask', spread)
            .gte('pop', pop.min_value)
            .gte('open_interest',  open_interest.min_value)
            .match(type_match)
            .in('underlying_symbol', symbols_to_search.split(","))
            .order(order_column, { ascending: order_ascending })
            .limit(1000)

            :

            // TODO: So messy, better clean this up
            (type == "Common Stock" ?

                 await this.supabase.from('cboe_fundatmentals').select()
                    .eq('is_in_the_money', false)
                    .gte('strike', strike.min_value).lte('strike', strike.max_value)
                    .gte('days_before_expiration', days_before_expiration.min_value).lte('days_before_expiration', days_before_expiration.max_value)
                    .gte('bid_over_ask', spread)
                    .gte('pop', pop.min_value)
                    .gte('open_interest',  open_interest.min_value)
                    .or(analyst_rating_condition)
                   // .lte('analyst_rating', rating.max_value)
                    .match(type_match)
                    .order(order_column, { ascending: order_ascending })
                    .limit(200)

                    :

                await this.supabase.from('cboe_fundatmentals').select()
                    .eq('is_in_the_money', false)
                    .gte('strike', strike.min_value).lte('strike', strike.max_value)
                    .gte('days_before_expiration', days_before_expiration.min_value).lte('days_before_expiration', days_before_expiration.max_value)
                    .gte('bid_over_ask', spread)
                    .gte('pop', pop.min_value)
                    .gte('open_interest',  open_interest.min_value)
                    .match(type_match)
                    .order(order_column, { ascending: order_ascending })
                    .limit(200)
            )


        console.log("error", error);

        return this.instanceItemsFromData(data);
    }

    static async getMatchingItems(should_fetch_puts, underlying_symbol, order_column, order_ascending){

        const order_column_2 = order_column == 'strike' ? 'expiration' : 'strike';
        const order_column_3 = ['expiration','days_before_expiration','strike'].includes(order_column) ? 'underlying_symbol' : 'expiration'
        console.log("--- order_column", order_column, order_column_2, order_column_3);

        const { data, error } = await this.supabase.from(should_fetch_puts ? "cboe_puts" : "cboe_calls").select()
            .eq('underlying_symbol',  underlying_symbol)
            .order(order_column, { ascending: order_ascending })
            .order(order_column_2, { ascending: true})
            .order(order_column_3, { ascending: true } )
            .limit(400);

        console.log("error", error);

        return this.instanceItemsFromData(data).map( result => {
            result.bfbm = result.bfbm > 0 && result.bfbm < 9999999 ? result.bfbm : 0;
            result.bfbc = result.bfbc > 0 && result.bfbc < 9999999 ? result.bfbc : 0;
            return result;
        });

        /*
        let cboes = this.instanceItemsFromData(data);

        return cboes.reduce( (output, cboe) => {
            let container = output.find( u => u.expiration == cboe.expiration );
            if(container == null || container == undefined){
                container = { expiration: cboe.expiration, cboes: [] };
                output.push(container);
            }
            container.cboes.push(cboe);
            return output;
        }, []).sort((a,b) => a.expiration - b.expiration)*/
    }

    get identifier(){
        return `${this.import_id}-${this.row_index}`;
    }
}