<template>
  <div id="gjs" class="grapes-js" ref="grapesjs_container"></div>
</template>

<script>
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import custom_code_plugin from 'grapesjs-custom-code';
import basic_blocks_plugin from '../../plugins/grapesjs-basic-blocks';
import {SupabaseClient} from "../../models/SuperbaseClient";



export default {
  props: {
    editor_components: null,
    editor_styles: null
  },
  data() {
    return {
      images: [],
      is_uploading: false,
    }
  },
  mounted(){
    window.grapesjs = grapesjs;
    window.html_editor = window.grapesjs.init({
      container: '#gjs',
      height: '800px',
      width: '100%',
      styleManager: {
        clearProperties: true,
        sectors: []
      },
      /*storageManager: {
        id: 'gjs-',
        type: 'local',
        autosave: true,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
      },*/
      deviceManager: {
        devices:
          [
            { id: 'desktop', name: 'Desktop', width: '',},
            { id: 'tablet', name: 'Tablet', width: '768px', widthMedia: '1280px' },
            { id: 'mobile', name: 'Mobile', width: '500px',  widthMedia: '960px' },
          ]
      },
      assetManager: {
        embedAsBase64: false,
        multiUpload: false,
        autoAdd: 1,
        uploadFile: this.uploadFile,
        // dropzone: false,
        // openAssetsOnDrop: false
       // headers: {
        //  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        //}
      },
      plugins: [ basic_blocks_plugin, custom_code_plugin ],//, custom_code_plugin],
      pluginsOpts: {
         [basic_blocks_plugin]: {
            blocks: [ 'container', 'block', 'column1', 'column2', 'column3', 'column3-7', 'text',  'link', 'image', 'video'],
           //blocks: ['text',  'link', 'image', 'video'],
        },
        [custom_code_plugin]:{

        }
      }
    });


    console.log("editor",window.html_editor);
    window.html_editor.on('load', this.editor_loaded);
      window.html_editor.on('asset:remove', this.image_removed);
      window.html_editor.on('asset:upload:start', () => {this.is_uploading = true});
      window.html_editor.on('asset:upload:end', () => {this.is_uploading = false});
      window.html_editor.on('asset:upload:error', () => {this.is_uploading = false; alert("Error: Could not upload image.")});
      //window.html_editor.on('block:add', this.block_added);
      //window.html_editor.on('component:add', this.component_added);
      //this.editor.on('component:selected', this.component_selected);
  },
  methods: {
    editor_loaded(){
      this.addTraitViewToStylePanel();
        //this.removeRichTextIcons();
          this.setEditorStyle();
          this.loadImages();
          //this.setClassesPrivate();
          this.setContent();
          this.isLoading = false;
    },
    addTraitViewToStylePanel(){
     let pn = window.html_editor.Panels;
      // Load and show settings and style manager
      let openTmBtn = pn.getButton('views', 'open-tm');
      openTmBtn && openTmBtn.set('active', 1);
      let openSm = pn.getButton('views', 'open-sm');
      openSm && openSm.set('active', 1);

      let traitsPanel = this.$el.querySelector('.gjs-traits-cs')//'.gjs-trt-traits');
      let stylePanel = this.$el.querySelector('.gjs-pn-views-container div div');

      console.log("traitsPanel", traitsPanel)
      console.log("stylePanel", stylePanel.children)
       stylePanel.insertBefore(traitsPanel, stylePanel.children[0]);

       pn.removeButton('views', 'open-tm');

       let openBlocksBtn = pn.getButton('views', 'open-blocks');
       openBlocksBtn && openBlocksBtn.set('active', 1);
    },
    setEditorStyle(){
      let css_link = document.createElement('link');
      css_link.href = "/grapesjs-editor.css";
      css_link.rel  = "stylesheet";
      css_link.type = "text/css";

      let iframe = this.$refs.grapesjs_container.querySelector("iframe");
      let iframe_document = iframe.contentDocument || iframe.contentWindow.document;
      iframe_document.head.append(css_link);

      console.log("iframe_document",  iframe_document);

      //iframe_document.body.on("paste", '[contenteditable="true"]', this.text_pasted);
      iframe_document.body.addEventListener("paste", this.text_pasted);
    },

    // -------------------------------------------

    setContent() {

      console.log("++++++++ set content");

      if (this.editor_components) {
        console.log("Set Components", this.editor_components)
        window.html_editor.setComponents(this.editor_components);
      }
      if (this.editor_styles) {
        console.log("Set STyles", this.editor_styles)
        window.html_editor.setStyle(this.editor_styles);
      }
    },

    getContent(){
      let html = window.html_editor.getHtml().replace("<body", "<div").replace("</body>", "</div>");
      let css = window.html_editor.getCss();
      let components = window.html_editor.getComponents();
      let styles = window.html_editor.getStyle();
      return { html: html, css: css,  components:  components, styles: styles };
    },



    // -------------- Images ------------------------

    loadImages(){
      window.html_editor.AssetManager.clear();
      let options = { limit: 100, offset: 0, sortBy: { column: 'created_at', order: 'desc' }}
      SupabaseClient.storage.from('content_pages').list('images',  options).then(({data, error}) => {
        for(let image of data) {
          if(!image.name.startsWith(".")) {
            //console.log("results", image)
            window.html_editor.AssetManager.add({
              type: 'image',
              src: `https://zclhghwmxemsdcfplyja.supabase.co/storage/v1/object/public/content_pages/images/${image.name}`,
              name: image.name
            });
          }
        }
      });
    },

    uploadFile(event){

      let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      if(files.length < 1) return;

      let file = files[0]

      this.isUploading = false;
      let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
      console.log("uniqueId", uniqueId, file);
      let options = { cacheControl: '3600', upsert: false };
      SupabaseClient.storage.from('content_pages').upload(`images/${uniqueId}.jpg`, file, options).then( response => {
        console.log("upload response", response);
        if(response.error){ alert(response.error) }
        else{ console.log();
          window.html_editor.AssetManager.add({
            type: 'image',
            src: `https://zclhghwmxemsdcfplyja.supabase.co/storage/v1/object/public/content_pages/${response.data.path}`,
            name: response.data.path.replace("images/", "")
          });
        }
      });
    },

    image_removed(img){
      console.log("remove", img);
      SupabaseClient.storage.from('content_pages').remove([`images/${img.attributes.name}`]).then(result => {
        console.log("deleted image", result)
      })
    },

    // ------------------- Component Events ------------



    component_added(component) {
      console.log("component_added.1");
      component.removeTrait('title');
      component.removeTrait('id');
      console.log("component_added.2");
    },


   /* block_added(block) {
      console.log("block_added.1");

      if (block.get("type") === "accordion") {
        let content = block.get("content");
        console.log("c", content);
        const new_id = new Date().getTime();
        console.log("new_id", new_id);
        content = content.replaceAll("THE_ID",  new_id );
        block.set({ content });
      }
    },*/
    // ------------------ Events ----------------------



    text_pasted(event){
      console.log("Text Pasted");
      event.preventDefault();

      if (event.target && event.target.getAttribute("contenteditable") === "true") {
        let clipboardData = event.clipboardData || window.clipboardData;
        if(clipboardData){
          let text = clipboardData.getData('Text');
          console.log("paste text!! " + text);
          event.target.ownerDocument.execCommand("insertText", false, text);
        }

        //e.target.ownerDocument.execCommand("insertHtml", false, stripHtml(text));
      }
    },

    // ---------- Utils

    stripHtml(html){
      let temporalDivElement = document.createElement("div");
      temporalDivElement.innerHTML = html;
      return temporalDivElement.textContent || temporalDivElement.innerText || "";
    },
  },
  watch:{
    editor_components(val) {
      if (val) {
        console.log("watch setComponents", val)
        window.html_editor.setComponents(val);
      }
    },
    editor_styles(val){
        if (val) {
        console.log("watch setStyle(", val);
        window.html_editor.setStyle(val);
      }
    }
  }
}
</script>
