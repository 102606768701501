import { createApp } from "vue";

//import { onAuthStateChanged } from "firebase/auth";

import App from './app.vue'
import router from './router/router'
import store from './store/store'

router.$store = store;

import { auth } from './firebase'
import './assets/scss/app.scss'

console.log("main.js");

//let app;

Number.prototype.asDollars = function(){
  return '$' + this.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

Date.prototype.asShortFormat = function(){
  return this.toLocaleDateString('en-US', { day: "numeric", month: "short", year: "numeric", timeZone: 'UTC'  })
}

//onAuthStateChanged(auth, user => {
 // if(app) return;

  let app = createApp(App)

  app.use(store);
  app.use(router);

  const mixins = require.context('./mixins/', true, /\.js$/);

  mixins.keys().forEach(key => {
    app.mixin(mixins(key).default);
  });


  const directives = require.context('./directives/', true, /\.js$/);

  directives.keys().forEach(key => {
    const directive = directives(key).default;
    const name = key.replace("./", "").replace(".js", "");
    app.directive(name, directive);
  });


  const vueComponents = require.context('./components/', true, /\.(vue|js)$/);

  vueComponents.keys().forEach(key => {
    const component = vueComponents(key).default;
    const name = component.name
      ? component.name
      : key.replace(/\.(\/|vue|js)/g, '').replace(/(\/|-|_|\s)\w/g, (match) => match.slice(1).toUpperCase());

    console.log('component', name);
    app.component(name, component);
  });


  app.mount('#app');
//});
