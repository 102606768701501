import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
import router from '../router/router'
import Cboe from "../models/Cboe";


const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage || {},
});

const store = createStore({
  plugins: [vuexLocal.plugin],
  state() {
    return {
      auth_session: null,
      comparisons: [],
      hidden_cboe_identifiers: [],
      default_fees: 1.20,
      scan_results: [],
    }
  },
  getters: {
    auth_session: (state) => state.auth_session,
    comparisons: (state) => state.comparisons,
    hidden_cboe_identifiers: (state) => state.hidden_cboe_identifiers,
    default_fees: (state) => state.default_fees,
    scan_results: (state) => state.scan_results
  },
  mutations: {
    setDefaultFees(state, val){
      this.default_fees = val;
    },
    setAuthSession(state, val) {
      state.auth_session = val;
    },
    addToHiddenCboeIdentifiers(state, val) {
      state.hidden_cboe_identifiers.push(val);
    },
    removeFromHiddenCboeIdentifiers(state, val) {
      if(state.hidden_cboe_identifiers.includes(val)) {
        const index = state.hidden_cboe_identifiers.indexOf(val);
        state.hidden_cboe_identifiers.splice(index, 1);
      }
    },
    addComparison(state, val) {
      state.comparisons.push(val);
    },
    removeComparisonAtIndex(state, index){
      state.comparisons.splice(index, 1);
    },
    setScanResults(state, val){
      state.scan_results = val;
    }
  },


  actions: {
/*
    search({commit, getters}, {search_params, order_column, order_ascending}){
      commit('setScanResults', []);
      Cboe.scan(this.search_params, this.order_column, this.order_ascending).then(scan_results => {
        commit('setScanResults', scan_results);
      });
    },
    toggleMobileMenu({commit, getters}){
      commit('setShowMobileMenu', !getters.show_mobile_menu);
    },
    closeMobileMenu({commit, getters}){
      console.log("close menu");
      commit('setShowMobileMenu', false);
    },
    async gmailSignIn({dispatch}){
      let result = await signInWithPopup(auth, new GoogleAuthProvider());
      await User.saveUser(result.user, GoogleAuthProvider.credentialFromResult(result));
      const token = await auth.currentUser.getIdTokenResult(true);

      if (token.claims.admin) { // User is admin
        await router.push('/admin');
        return;
      }

      // Wait for custom claims to be updated.
      let unsubscribe = Session.onDocumentUpdate(token.claims.user_id, session => {

        auth.currentUser.getIdTokenResult(true).then((tokenResult) => {
            if (tokenResult.claims.admin) { // User is admin
              unsubscribe();
              router.push('/admin')
            }
        });
      });
    },*/
  }
});

export default store;
