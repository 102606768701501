import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator }  from "firebase/functions";

//import { getAnalytics } from "firebase/analytics";

const production = {
  apiKey: "AIzaSyAtt4IvxfHartegKMjr6_cYCgwovQZmg-c",
  authDomain: "poptions-us.firebaseapp.com",
  projectId: "poptions-us",
  storageBucket: "poptions-us.appspot.com",
  messagingSenderId: "712330684090",
  appId: "1:712330684090:web:5c3a2468e5a077c11d4483",
  measurementId: "G-44N8M5YQMD"
};

const staging = {
  apiKey: "AIzaSyA4irt9PcaNUYwrDXQFVmjSn--bCxdFK0E",
  authDomain: "poptions-us-staging.firebaseapp.com",
  projectId: "poptions-us-staging",
  storageBucket: "poptions-us-staging.appspot.com",
  messagingSenderId: "223526912839",
  appId: "1:223526912839:web:710ad154f9f071c0d50262"
}

const firebase_config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};
console.log("process.env", process.env);
console.log("config", firebase_config);

const firebaseApp = initializeApp(firebase_config);
//const analytics = getAnalytics(firebaseApp);

// utils
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, "us-east4");

//connectFunctionsEmulator(functions, 'localhost', '5001');
//connectFirestoreEmulator(db, 'localhost', 5002);

export {
  db,
  auth,
  functions
}


