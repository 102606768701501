import blocks from "./blocks";
import styles from "./styles";
import commands from './commands';
import panels from './panels';

const plugin = (editor, opts = {}) => {
    const config = {
        blocks: [
            "container",
            "block",
            "column1",
            "column2",
            "column3",
            "column3-7",
            "text",
            "link",
            "image",
            "video",
            "map"
        ],
        flexGrid: true,
        stylePrefix: "gjs-",
        addBasicStyle: true,
        category: "Basic",
        labelColumn1: "1 Column",
        labelColumn2: "2 Columns",
        labelColumn3: "3 Columns",
        labelColumn37: "2 Columns 3/7",
        labelText: "Text",
        labelLink: "Link",
        labelImage: "Image",
        labelVideo: "Video",
        labelMap: "Map",
        rowHeight: 75,
        ...opts
    }

    blocks(editor, config);
    styles(editor, config);
    commands(editor, config);
    panels(editor, config);
}

export default plugin
