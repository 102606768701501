<template>
  <div>
     <!-- <div v-if="user_email" style="display:flex; justify-content:flex-end; padding:2px 8px; font-size:12px; font-weight:400;">
        <a @click="logout__clicked">Logged on as: {{ user_email }}</a>
      </div> -->

    <router-view></router-view>
  </div>
</template>


<script>


import { SupabaseClient } from './models/SuperbaseClient'
import router from './router/router'

export default {
  data(){
    return{
      session: null,
      cam: 0,
    }
  },
  mounted(){

    SupabaseClient.auth.getSession().then(({ data }) => {
      this.$store.commit('setAuthSession', data.session);
      console.log("auth_session", this.auth_session)
    });

    SupabaseClient.auth.onAuthStateChange((_, _session) => {
      this.$store.commit('setAuthSession', _session);
    });
  },
  computed:{
    auth_session(){
     return this.$store.getters.auth_session;
    },
    user_email(){
      return this.$store.getters.auth_session && this.$store.getters.auth_session.user ? this.$store.getters.auth_session.user.email : null;
    }
  },
  methods:{
    async logout__clicked(event){
      try {
        console.log("log out");
        let { error } = await SupabaseClient.auth.signOut();
        console.log("error", error);
        if (error) throw error
        router.push("/")
      } catch (error) {
        alert(error.message)
      }
    }
  }
}
</script>
